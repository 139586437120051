/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
// src/components/Detail.js

import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosData } from "../Config/Axios";
// import { toast } from "react-toastify";
import LoaderCard from "./Loader/LoaderCard";
import { AuthContext } from "../Config/Context/Context";
import { HandleFav } from "../Helper/AddToFavServices";
import { PhoneFun, WhatsappFun } from "../Helper/CallingServices";

function Detail({ states, cities }) {
  let { filters } = useContext(AuthContext);

  // const filter = states?.filter(
  //   (e) => e.value === Number(filters.selectedState)
  // );
  // console.log(states)
  // const filter1 = cities?.filter(
  //   (e) => e.value === Number(filters.selectedCity)
  // );

  const { state } = useLocation();

  const [, setHeaderColor] = useState("transparent");
  const [currentPage] = useState(1);
  const [postsPerPage] = useState(20);
  const [grid, setgrid] = useState("grid");
  const [conditioncheck, setconditionchek] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  // const token = localStorage.getItem("Token");

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  console.log(filters);
  const navigate = useNavigate();
  // let {filters} = useContext(AuthContext)
  const GetData = () => {
    setLoader(true);

    const queryParams = [];

    if (filters.cityTown) queryParams.push(`city=${filters.cityTown}`);
    if (filters.price) queryParams.push(`price=${filters.price}`);
    if (filters.years) queryParams.push(`year=${filters.years}`);
    if (filters.transmissions)
      queryParams.push(`transmission=${filters.transmissions}`);

    if (filters.rentPeriod)
      queryParams.push(`rent_period=${filters.rentPeriod}`);
    if (filters.availability)
      queryParams.push(`availability=${filters.availability}`);
    if (filters.beds) queryParams.push(`bed=${filters.beds}`);
    if (filters.baths) queryParams.push(`bath=${filters.baths}`);
    if (filters.sorts) queryParams.push(`sort=${filters.sorts}`);
    if (filters.fuelType) queryParams.push(`fuel_type=${filters.fuelType}`);
    if (filters.engineSize)
      queryParams.push(`engine_size=${filters.engineSize}`);
    if (filters.doors) queryParams.push(`doors=${filters.door}`);

    if (filters.selectedMake) {
      // const stateLabel = filter[0]?.label;
      queryParams.push(`make=${filters?.selectedMake}`);
    }
    if (filters.selectedtrim) {
      // const stateLabel = filter[0]?.label;
      queryParams.push(`trim=${filters?.selectedtrim}`);
    }
    if (filters.selectedModel) {
      // const stateLabel = filter[0]?.label;
      queryParams.push(`model=${filters?.selectedModel}`);
    }
    // const type = filters.type ? filters.type : "sale";
    // const sort = filters.sorts ? filters.sorts : "asc";
    // const cityLabel = filters.selectedCity ? filter1[0]?.label : "";

    // queryParams.push(`region=${stateLabel}`);
    // queryParams.push(`carstype=${type}`);
    // queryParams.push(`sort=${sort}`);
    // if (cityLabel) queryParams.push(`district=${cityLabel}`);

    const url = `/car-user?${queryParams.join("&")}`;

    axiosData
      .get(url)
      .then((res) => {
        if (res?.data?.status === true) {
          setData(res?.data?.data);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  useEffect(() => {
    if (conditioncheck || !state) {
      GetData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  useEffect(() => {
    if (state) {
      setData(state);
      setconditionchek(true);
      setLoader(false);
    }

    window.scrollTo(0, 0);
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < 1700) {
        setHeaderColor("blue");
      } else {
        setHeaderColor("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // const indexOfLastPost = currentPage * postsPerPage;
  // const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <>
      <div
        className="properties-split pt-0 pb-0 bg-white"
        id="properties-split"
      >
        <div className="container-fluid">
          <div className="">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div
                className={`row pt-45 properties-list  ${
                  grid === "grid" ? "listgrid" : "listgrid1"
                }`}
              >
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="properties-filter clearfix">
                    <div className="select--box pull-left">
                      <label>{data?.length} Results Found</label>
                    </div>
                    <div className={`view--type pull-right faiz`}>
                      <a
                        className={grid === "list" ? "active" : ""}
                        href="#"
                        id="switch-list"
                        onClick={() => setgrid("list")}
                      >
                        <i className="fa fa-th-list" />
                      </a>
                      <a
                        className={grid === "grid" ? "active" : ""}
                        href="#"
                        id="switch-grid"
                        onClick={() => setgrid("grid")}
                      >
                        <i className="fa fa-th-large" />
                      </a>
                    </div>
                  </div>
                </div>

                <div
                  className={`properties properties-grid ${
                    grid === "list" ? "newhello1" : "newhello"
                  }`}
                >
                  {loader ? (
                    <LoaderCard />
                  ) : (
                    data.map((e) => (
                      <div key={e.id} className="col-xs-12 col-sm-12 col-md-12">
                        <div
                          className={`property-item ${
                            grid === "list" ? "listby" : ""
                          }`}
                        >
                          <div className="property--img">
                            <img
                              alt="property image"
                              onClick={() =>
                                navigate(`/en-gh/singlecar/${e.id}`)
                              }
                              className={`img-responsive ${
                                grid === "list" ? "h-274" : "h-200"
                              }`}
                              src={e.car_images?.[0]?.image}
                            />
                                                       <span className={e?.carstype ==='sale' ? 'property--status':'property--statusred'}>

                              For {e?.carstype === "sale" ? "Sale" : "Rent"}
                            </span>
                          </div>
                          <div className="property--content">
                            <div className="property--info">
                              <h5 className="property--title">
                                <a href="#">{`${e?.year} ${e?.make?.name} ${e?.model?.name} ${e?.trim}`}</a>

                                <span
                                  className="value"
                                  onClick={() => HandleFav(e?.id)}
                                  style={{ float: "right" }}
                                >
                                  {e?.is_favourite === 1 ? (
                                    <i
                                      className="fa fa-heart"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : (
                                    <i
                                      className="fa fa-heart-o"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </span>
                              </h5>
                              <p className="property--location">
                                <i className="fa fa-map-marker" />
                                {e?.city} ,{e?.district}, {e?.region}
                                <span style={{ float: "right" }}>
                                  {e?.created_at}
                                </span>
                              </p>
                              <div className="flex1">
                                {e?.carstype === "sale" ? (
                                  <p
                                    className="property--price "
                                    style={{ display: "flex" }}
                                  >
                                    GH₵{e?.price}
                                  </p>
                                ) : (
                                  <p
                                    className="property--price "
                                    style={{ display: "flex" }}
                                  >
                                    GH₵{e?.price_per_day}
                                    <sup>/Day</sup>
                                  </p>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    width: "100%",
                                  }}
                                >
                                  <button
                                    className="bg-black p-xs border-none button-detail"
                                    onClick={() => PhoneFun(e, user)}
                                  >
                                    <i
                                      className="fa fa-phone"
                                      style={{ color: "white" }}
                                    />
                                  </button>
                                  <button
                                    className="btn--primaryw  p-xs ml-xs border-none button-detail"
                                    onClick={() => WhatsappFun(e, user)}
                                  >
                                    <i
                                      className="fa fa-whatsapp"
                                      style={{ color: "white" }}
                                    />
                                  </button>
                                  {e?.host?.is_verified == "1" && (
                                    <img
                                      style={{
                                        width: "50px",
                                        marginTop: "-3px",
                                      }}
                                      src="/assets/images/verify.webp"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="property--features">
                              {/* {/* <p>{e?.description.slice(0, 83)}</p> */}
                              <div className="flex1">
                              <div className="">
                                  <img
                                    className="mtt-10"
                                    src="/assets/images/mileagelogo.svg"
                                    alt=""
                                  />
                                  <span className="ml-nine bold font-20 text-black ">
                                    {e?.mileage_type +' '+e?.mileage}
                                  </span>
                                </div>
                                <div className="">
                                  <img
                                    className="mtt-10"
                                    src="/assets/images/transmissionlogo.svg"
                                    alt=""
                                  />
                                  <span className="ml-nine bold font-20 text-black ">
                                    {e?.transmission}
                                  </span>
                                </div>
                                <div className="">
                                  <img
                                    className="mtt-10"
                                    src="/assets/images/enginelogo.svg"
                                    alt=""
                                  />
                                  <span className="ml-nine bold font-20 text-black ">
                                    {e?.engine_size?.name}
                                  </span>
                                </div>
                                {/* <div className="">
                               
                                  <img
                                    className="mtt-10"
                                    src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-fuel-type_670bab1e0b56aac2d6635a5cb353099061396d70dca9c904ed4d5cf16c704c58.svg"
                                    alt=""
                                  />
                                  <span className="ml-nine bold font-20 text-black ">
                                    {e?.fuel_type}
                                  </span>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Detail;
