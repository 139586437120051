/* eslint-disable no-lone-blocks */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
// import { axiosInstance } from "../Config/AxiosInstance"
import SocialSidebar from "../../Component/SocialSidebar";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

import {
  // Availability,
  // allowed,
  // amenities,
  GetData,
  // GetDataone,
  // Gettitle,
  // Rent_Period,
  // types,
  //  amenities
} from "../../Component/Aminittes";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { baseURL } from "../../Config/Baseurl";
import {
  dropdownOptions,
  // dropdownOptions,
  fetchStates,
  geonamesUsername,
  Getbodytype,
  Getcondition,
  Getdoors,
  GetengineSize,
  Getexterior,
  Getinterior,
  GetMake,
  Getmodel,
  Year,
  // GetTrim,
  // Getvariant,
  // years,
} from "../../Helper/PropertyService";

// import { GoogleMap, MarkerF } from "@react-google-maps/api";
const selectStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black",
  }),
};
function EditCarForRent() {
  const { state } = useLocation();
  // const { state } = useLocation();
  const [selectedState, setSelectedState] = useState({
    value: state?.region,
    label: state?.region,
  });

  const [selectedCity, setSelectedCity] = useState({
    value: state?.district,
    label: state?.district,
  });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [engineSize, setengineSize] = useState([]);
  const [doors, setdoors] = useState([]);
  const [interior, setinterior] = useState([]);
  const [bodytype, setbodytype] = useState([]);
  const [exterior, setexterior] = useState([]);
  const [year, setyear] = useState([]);
  const [condition, setcondition] = useState([]);
  const [, setDistricts] = useState([]);
  const [, setLoading] = useState(false);
  const [, setError] = useState(null);

  const [Make, setmake] = useState([]);
  const [model, setmodel] = useState([]);
  // const [variant, setvariant] = useState([]);
  // const [trim, settirm] = useState([]);
  const [, setloader] = useState([]);
  useEffect(() => {
    GetData().then((e) => {
      setfeature(e?.data?.data);
    });
    GetMake(setloader, setmake);
    GetengineSize(setloader, setengineSize);
    Getdoors(setloader, setdoors);
    Getinterior(setloader, setinterior);
    Year(setyear);
    Getexterior(setloader, setexterior);
    Getbodytype(setloader, setbodytype);
    Getcondition(setloader, setcondition);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMakeChange = (event, setFieldValue) => {
    const selectedMakeId = event.target.value;
    setFieldValue("make_id", selectedMakeId);
    Getmodel(selectedMakeId, setloader, setmodel);
  };
  useEffect(() => {
    Getmodel(state?.make?.id, setloader, setmodel);
    // Getvariant(state?.model?.id, setloader, setvariant, state?.make?.id);
    // GetTrim(
    //   state?.variant?.id,
    //   setloader,
    //   settirm,
    //   state?.make?.id,
    //   state?.model?.id
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const handleModelChange = (event, setFieldValue, make_id) => {
  //   const selectedModelId = event.target.value;
  //   setFieldValue("model_id", selectedModelId);
  //   Getvariant(selectedModelId, setloader, setvariant, make_id);
  // };
  // const handlevariantChange = (event, setFieldValue, make_id, model_id) => {
  //   const selectedvariantId = event.target.value;
  //   setFieldValue("variant_id", selectedvariantId);
  //   GetTrim(selectedvariantId, setloader, settirm, make_id, model_id);
  // };

  const [selectedAmenities, setSelectedAmenities] = useState(
    JSON.parse(state?.amenities) || []
  );
  console.log(state);

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    setSelectedAmenities((prevSelected) => {
      if (checked) {
        return [...prevSelected, id];
      } else {
        return prevSelected.filter((item) => item !== id);
      }
    });
  };
  const [feature, setfeature] = useState([]);

  const validationSchema = Yup.object().shape({
    make_id: Yup.string(),
    model_id: Yup.string(),
    year: Yup.string().required("Year is required"),
    trim_id: Yup.string(),
    // variant_id: Yup.string(),
    body_type: Yup.string(),
    exterior: Yup.string(),
    interior: Yup.string(),
    condition: Yup.string(),
    // price: Yup.string(),
    // carstype: Yup.string().required("Car Type is required"),
    trim_other: Yup.string(),
    transmission: Yup.string().required("transmission is required"),

    make_other: Yup.string(),
    // variant_other: Yup.string(),
    model_other: Yup.string(),
    vin: Yup.string(),
    fuel_tank: Yup.string(),
    fuel_type: Yup.string(),
    days_hours: Yup.string(),
    pickup_location: Yup.string(),
    pickup_time: Yup.string(),
    dropoff_location: Yup.string(),
    // location: Yup.string(),
    dropoff_time: Yup.string(),
    price_per_day: Yup.string().required("Price is required"),
    link: Yup.string(),
    negotiable: Yup.string(),
    mileage: Yup.string(),
    mileage_type: Yup.string(),
    no_of_door: Yup.string(),
    engine_size: Yup.string(),
    city: Yup.string().required("City is required"),
    description: Yup.string().required(" Description is required"),
  });
  const Navigate = useNavigate();
  // console.log(state?.variant?.id)
  const initialValues = {
    make_id: state?.make?.id || "",
    variant_id: state?.variant?.id || "",
    variant_other: state?.variant_other || "",
    trim_other: state?.trim_other || "",
    link: state?.link || "",
    model_id: state?.model?.id || "",
    year: state?.year_id || "",
    transmission: state?.transmission || "",
    trim_id: state?.trim || "",
    body_type: state?.body_type?.id || "",
    engine_size: state?.engine_size?.id || "",
    no_of_door: state?.door?.id || "",
    exterior: state?.exterior?.id || "",
    interior: state?.interior?.id || "",
    model_other: state?.model_other || "",
    mileage: state?.mileage || "",
    mileage_type: state?.mileage_type || "",
    condition: state?.condition?.id || "",
    vin: state?.vin || "",
    fuel_tank: state?.fuel_tank || "",
    fuel_type: state?.fuel_type || "",
    carstype: state?.carstype || "",
    days_hours: state?.days_hours || "",
    pickup_location: state?.pickup_location || "",
    pickup_time: state?.pickup_time || "",
    dropoff_location: state?.dropoff_location || "",
    // location: state?.location || "",
    city: state?.city || "",
    dropoff_time: state?.dropoff_time || "",
    price_per_day: state?.price_per_day || 200, // Assuming a default of 200 if not set
    negotiable: state?.negotiable || "",
    // price: state?.price || "",
    description: state?.description || "",
  };

  const formattedList = state?.car_images?.map((item) => `${item.image}`) || [];

  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState(formattedList);

  useEffect(() => {
    const newImageUrls = images.map((image) => URL.createObjectURL(image));
    setImageURLs((prev) => [...prev, ...newImageUrls]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  function onImageChange(e) {
    setImages([...e.target.files]);
  }

  function deleteImage(index, i) {
    const newImages = images.filter((_, i) => i !== index);
    const newImageURLs = imageURLs.filter((_, i) => i !== index);
    setImages(newImages);
    setImageURLs(newImageURLs);
  }

  function deleteAllImages() {
    setImages([]);
    setImageURLs(formattedList); // Reset to formattedList
  }
  const token = localStorage.getItem("Token");
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const Data = new FormData();
    {
      if (
        typeof values?.make_other === "string" &&
        values?.make_other.trim() !== ""
      ) {
        Data.append("make_other", values.make_other);
        // Data.append("make_id", "");
      } else {
        Data.append("make_id", values.make_id);
      }
    }

    {
      if (
        typeof values?.model_other === "string" &&
        values?.model_other.trim() !== ""
      ) {
        Data.append("model_other", values.model_other);
      } else {
        Data.append("model_id", values.model_id);
      }
    }
    // {
    //   if (
    //     typeof values?.variant_other === "string" &&
    //     values?.variant_other.trim() !== ""
    //   ) {
    //     Data.append("variant_other", values.variant_other);
    //   } else {
    //     Data.append("variant_id", values.variant_id);
    //   }
    // }
    {
      if (
        typeof values?.trim_other === "string" &&
        values?.trim_other.trim() !== ""
      ) {
        Data.append("trim_other", values.trim_other);
      } else {
        Data.append("trim", values.trim_id);
      }
    }

    Data.append("year", values.year);
    Data.append("body_type_id", values.body_type);
    Data.append("exterior_id", values.exterior);
    Data.append("interior_id", values.interior);
    Data.append("engine_size_id", values.engine_size);
    Data.append("door_id", values.no_of_door);
    Data.append("mileage", values.mileage);
    Data.append("mileage_type", values.mileage_type);
    Data.append("condition_id", values.condition);
    Data.append("link", values.link);
    Data.append("city", values.city);
    Data.append("vin", values.vin);
    Data.append("country", "Ghana");
    Data.append("district", selectedCity?.label);
    Data.append("region", selectedState?.label);
    Data.append("fuel_tank", values.fuel_tank);
    Data.append("transmission", values.transmission);
    // Data.append("location", values.location);
    Data.append("carstype", 'rent');
    Data.append("days_hours", values.days_hours);
    Data.append("pickup_location", values.pickup_location);
    Data.append("fuel_type", values.fuel_type);
    Data.append("pickup_time", values.pickup_time);
    Data.append("dropoff_location", values.dropoff_location);
    Data.append("dropoff_time", values.dropoff_time);
    Data.append("price_per_day", values.price_per_day || 200);
    Data.append("negotiable", values.negotiable);
    // Data.append("price", values.price);
    Data.append("description", values.description);
    Data.append("amenities", JSON.stringify(selectedAmenities));

    images?.map((item, index) => {
      Data.append(`images[${index}][file]`, item);
    });

    try {
      await axios
        .post(`${baseURL}/car/${state?.id}`, Data, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "X-Http-Method-Override": "PUT",
          },
        })
        .then((response) => {
          toast.success(response?.data?.message);
          if (response?.data?.status === true) {
            Navigate(-1);
          }
        });
    } catch (error) {
      toast.error(error?.message);
      console.error(error);
    }
  };
  const [otherInput, setOtherInput] = useState({
    make: "",
    model: "",
    variant: "",
    trims: "",
  });
  useEffect(() => {
    fetchStates(setLoading, setStates, geonamesUsername);
  }, []);

  useEffect(() => {
    if (selectedState) {
      const fetchCities = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `https://secure.geonames.org/childrenJSON?geonameId=${selectedState.value}&username=${geonamesUsername}`
          );
          const cityOptions = response.data.geonames.map((city) => ({
            value: city.geonameId,
            label: city.name,
          }));
          setCities(cityOptions);
        } catch (error) {
          setError("Error fetching cities");
          console.error("Error fetching cities:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchCities();
    } else {
      setCities([]);
      setDistricts([]);
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity) {
      const fetchDistricts = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `https://secure.geonames.org/childrenJSON?geonameId=${selectedCity.value}&username=${geonamesUsername}`
          );
          const districtOptions = response.data.geonames.map((district) => ({
            value: district.geonameId,
            label: district.name,
          }));
          setDistricts(districtOptions);
        } catch (error) {
          setError("Error fetching districts");
          console.error("Error fetching districts:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchDistricts();
    } else {
      setDistricts([]);
    }
  }, [selectedCity]);

  return (
    <section
      id="add-property"
      className="add-property"
      style={{ marginTop: "0px" }}
    >
      <div className="container">
        <div className="row">
          <SocialSidebar />
          <div className="col-xs-12 col-sm-12 col-md-8">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {({ isSubmitting, errors, touched, values, setFieldValue }) => (
                <Form className="mb-0">
                  {console.log(errors)}
                  <div className="form-box">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <h4 className="form--title">Car Description</h4>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6">
                          <div className="form-group">
                            <label htmlFor="make"> Make*</label>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {otherInput?.make ? (
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.make && touched.make
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="make_other"
                                  id="make"
                                  placeholder="Enter Make"
                                />
                              ) : (
                                <Field
                                  as="select"
                                  placeholder="Select Make"
                                  onChange={(event) =>
                                    handleMakeChange(event, setFieldValue)
                                  }
                                  className={`form-control ${
                                    errors.make && touched.make
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id="select-make"
                                  name="make_id"
                                >
                                  <option value={""} key={""} disabled>
                                    select Make
                                  </option>
                                  {Make?.map((e, i) => (
                                    <option value={e?.id} key={i}>
                                      {e?.name}
                                    </option>
                                  ))}
                                </Field>
                              )}
                              <button
                                type="button"
                                className="btn btn-secondary mt-2 ms-2"
                                onClick={() => {
                                  const newValue = !otherInput.make;
                                  setOtherInput({
                                    make: newValue,
                                    model: newValue,
                                    variant: newValue,
                                    trims: newValue,
                                  });
                                }}
                              >
                                {otherInput?.make ? "Cancel" : "Other"}
                              </button>
                            </div>
                            <ErrorMessage
                              name={otherInput?.make ? "make_other" : "make_id"}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-6">
                          <div className="form-group">
                            <label htmlFor="model"> Model*</label>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {otherInput?.model || otherInput?.make ? (
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.model && touched.model
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="model_other"
                                  id="model_other"
                                  placeholder="Enter Model"
                                />
                              ) : (
                                <Field
                                  as="select"
                                  placeholder="Select model"
                                  className={`form-control ${
                                    errors.model && touched.model
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  disabled={!values?.make_id}
                                  // onChange={(event) =>
                                  //   handleModelChange(
                                  //     event,
                                  //     setFieldValue,
                                  //     values?.make_id
                                  //   )
                                  // }
                                  id="select-model"
                                  name="model_id"
                                >
                                  {model?.map((e, i) => (
                                    <option value={e?.id} key={i}>
                                      {e?.name}
                                    </option>
                                  ))}
                                </Field>
                              )}
                              <button
                                disabled={!values?.make_id}
                                type="button"
                                className="btn btn-secondary mt-2 ms-2"
                                onClick={() => {
                                  const newValue = !otherInput.model;
                                  setOtherInput((prev) => ({
                                    ...prev,
                                    model: newValue,
                                    variant: newValue,
                                    trims: newValue,
                                  }));
                                }}
                              >
                                {otherInput?.model ? "Cancel" : "Other"}
                              </button>
                            </div>
                            <ErrorMessage
                              name={
                                otherInput?.model ? "model_other" : "model_id"
                              }
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>

                        {/* <div className="col-xs-12 col-sm-6 col-md-6">
                          <div className="form-group">
                            <label htmlFor="variant"> Variant*</label>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {otherInput?.variant ||
                              otherInput?.model ||
                              otherInput?.make ? (
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.variant_id && touched.variant_id
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="variant_other"
                                  id="variant_other"
                                  placeholder="Enter Variant"
                                />
                              ) : (
                                <Field
                                  as="select"
                                  placeholder="Select variant"
                                  className={`form-control ${
                                    errors.variant_id && touched.variant_id
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  disabled={!values?.model_id}
                                  onChange={(event) =>
                                    handlevariantChange(
                                      event,
                                      setFieldValue,
                                      values?.make_id,
                                      values?.model_id
                                    )
                                  }
                                  id="select-variant"
                                  name="variant_id"
                                >
                                  {variant?.map((e, i) => (
                                    <option value={e?.id} key={i}>
                                      {e?.name}
                                    </option>
                                  ))}
                                </Field>
                              )}
                              <button
                                disabled={!values?.model_id}
                                type="button"
                                className="btn btn-secondary mt-2 ms-2"
                                onClick={() => {
                                  const newValue = !otherInput.variant;
                                  setOtherInput((prev) => ({
                                    ...prev,
                                    variant: newValue,
                                    trims: newValue,
                                  }));
                                }}
                              >
                                {otherInput?.variant ? "Cancel" : "Other"}
                              </button>
                            </div>
                            <ErrorMessage
                              name={
                                otherInput?.variant
                                  ? "variant_other"
                                  : "variant_id"
                              }
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div> */}

                        <div className="col-xs-12 col-sm-6 col-md-12">
                          <div className="form-group">
                            <label htmlFor="trim"> Trim</label>
                            <Field
                              type="text"
                              className={`form-control ${
                                errors.trim_other && touched.trim_other
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="trim_id"
                              id="trim_id"
                              placeholder="Enter Trim"
                            />
                            {/* <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {otherInput?.trims ||
                              otherInput?.variant ||
                              otherInput?.model ||
                              otherInput?.make ? (
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.trim_other && touched.trim_other
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="trim_other"
                                  id="trim_other"
                                  placeholder="Enter Trim"
                                />
                              ) : (
                                <Field
                                  as="select"
                                  placeholder="Select trim"
                                  className={`form-control ${
                                    errors.trim_id && touched.trim_id
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  disabled={!values?.variant_id}
                                  id="select-trim_id"
                                  name="trim_id"
                                >
                                  <option value={""} key={""} >
                                    select Trim
                                  </option>
                                  {trim?.map((e, i) => (
                                    <option value={e?.id} key={i}>
                                      {e?.name}
                                    </option>
                                  ))}
                                </Field>
                              )}
                              <button
                                disabled={!values?.variant_id}
                                type="button"
                                className="btn btn-secondary mt-2 ms-2"
                                onClick={() => {
                                  const newValue = !otherInput.trims;
                                  setOtherInput((prev) => ({
                                    ...prev,
                                    trims: newValue,
                                  }));
                                }}
                              >
                                {otherInput?.trims ? "Cancel" : "Other"}
                              </button>
                            </div> */}
                            <ErrorMessage
                              name={
                                otherInput?.trims ? "trim_other" : "trim_id"
                              }
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="year">Select year*</label>
                          <Field
                            as="select"
                            placeholder="Select year"
                            className={`form-control ${
                              errors.year && touched.year ? "is-invalid" : ""
                            }`}
                            // disabled={!values?.make}
                            // onChange={(event) => handleyearChange(event, setFieldValue,values?.make,values?.model)}
                            id="select-year"
                            name="year"
                          >
                            {year?.map((e, i) => {
                              return (
                                <option value={e?.id} key={i}>
                                  {e?.name}
                                </option>
                              );
                            })}
                          </Field>
                          <ErrorMessage
                            name="year"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="select-type">State*</label>
                          <div className="select--box">
                            <i className="fa fa-angle-down" />
                            <Select
                              placeholder="Select State/Region"
                              options={states}
                              styles={selectStyles}
                              defaultValue={selectedState}
                              onChange={setSelectedState}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="District">District*</label>
                          <div className="select--box">
                            <i className="fa fa-angle-down" />
                            <Select
                              placeholder="Select District"
                              options={cities}
                              styles={selectStyles}
                              defaultValue={selectedCity}
                              onChange={setSelectedCity}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-12">
                        <div className="form-group">
                          <label htmlFor="city">City*</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.city && touched.city ? "is-invalid" : ""
                            }`}
                            name="city"
                            id="city"
                            placeholder="Enter City/Town"
                            required=""
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="price_per_day">Price For Rent*</label>
                          <Field
                            type="number"
                            className={`form-control ${
                              errors.price_per_day && touched.price_per_day
                                ? "is-invalid"
                                : ""
                            }`}
                            name="price_per_day"
                            id="price_per_day"
                            placeholder="Per Day (GH¢200.00 / day)"
                            required=""
                          />
                          <ErrorMessage
                            name="price_per_day"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="year">Select Transmission*</label>
                          <Field
                            as="select"
                            placeholder="Select transmission"
                            className={`form-control ${
                              errors.transmission && touched.transmission
                                ? "is-invalid"
                                : ""
                            }`}
                            id="transmission"
                            name="transmission"
                          >
                            <option value={""} key={""} disabled selected>
                              select transmission
                            </option>
                            {dropdownOptions?.transmissions?.map(
                              (option, i) => (
                                <option key={i} value={option.value}>
                                  {option.label}
                                </option>
                              )
                            )}
                          </Field>
                          <ErrorMessage
                            name="exterior"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="year">Select Engine Size</label>
                          <Field
                            as="select"
                            placeholder="Select Engine Size"
                            className={`form-control ${
                              errors.engine_size && touched.engine_size
                                ? "is-invalid"
                                : ""
                            }`}
                            id="select-engine_size"
                            name="engine_size"
                          >
                            <option value={""} key={""} disabled>
                              select Engine Size
                            </option>
                            {engineSize.map((option, i) => (
                              <option key={i} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="engine_size"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="form-group">
                          <label htmlFor="property-description">
                            Car Description*
                          </label>
                          <Field
                            as="textarea"
                            className={`form-control ${
                              errors.description && touched.description
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Describe your Car in detail"
                            name="description"
                            id="property-description"
                            rows={2}
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="year">Select No of Door</label>
                          <Field
                            as="select"
                            placeholder="Select Door"
                            className={`form-control ${
                              errors.no_of_door && touched.no_of_door
                                ? "is-invalid"
                                : ""
                            }`}
                            id="select-no_of_door"
                            name="no_of_door"
                          >
                            <option value={""} key={""} disabled>
                              select No Of Door
                            </option>
                            {doors.map((option, i) => (
                              <option key={i} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="no_of_door"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="year">Select Body Type</label>
                          <Field
                            as="select"
                            placeholder="Select Body type"
                            className={`form-control ${
                              errors.body_type && touched.body_type
                                ? "is-invalid"
                                : ""
                            }`}
                            id="select-body_type"
                            name="body_type"
                          >
                            <option value={""} key={""} disabled>
                              select Body Type
                            </option>
                            {bodytype.map((option, i) => (
                              <option key={i} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="body_type"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="year">Select Exterior Color</label>
                          <Field
                            as="select"
                            placeholder="Select Exterior"
                            className={`form-control ${
                              errors.exterior && touched.exterior
                                ? "is-invalid"
                                : ""
                            }`}
                            id="select-exterior"
                            name="exterior"
                          >
                            <option value={""} key={""} disabled>
                              select Exterior
                            </option>
                            {exterior.map((option, i) => (
                              <option key={i} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="exterior"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="year">Select Interior Color</label>
                          <Field
                            as="select"
                            placeholder="Select Interior"
                            className={`form-control ${
                              errors.interior && touched.interior
                                ? "is-invalid"
                                : ""
                            }`}
                            id="select-interior"
                            name="interior"
                          >
                            <option value={""} key={""} disabled>
                              select Interior
                            </option>
                            {interior.map((option, i) => (
                              <option key={i} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="interior"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="mileage">Mileage</label>
                          <Field
                            as="select"
                            className={`form-control ${
                              errors.mileage && touched.mileage
                                ? "is-invalid"
                                : ""
                            }`}
                            id="select-mileage"
                            name="mileage"
                          >
                            <option value="">Select Mileage</option>
                            <option value="MI">MI</option>
                            <option value="KM">KM</option>
                          </Field>
                          <ErrorMessage
                            name="mileage"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="condition">Condition</label>
                          <Field
                            as="select"
                            className={`form-control ${
                              errors.condition && touched.condition
                                ? "is-invalid"
                                : ""
                            }`}
                            id="select-condition"
                            name="condition"
                          >
                            <option value="">Select Condition</option>

                            {condition.map((option, i) => (
                              <option key={i} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="condition"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      {/* <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="carstype">Car Type</label>
                          <Field
                            as="select"
                            className={`form-control ${
                              errors.carstype && touched.carstype
                                ? "is-invalid"
                                : ""
                            }`}
                            id="select-carstype"
                            name="carstype"
                          >
                            <option value="">Select Car Type</option>
                            <option value="sale">Sale</option>
                            <option value="rent">Rent</option>
                          </Field>
                          <ErrorMessage
                            name="carstype"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div> */}
                      {/* <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="price">Price*</label>
                          <Field
                            type="number"
                            className={`form-control ${
                              errors.price && touched.price ? "is-invalid" : ""
                            }`}
                            name="price"
                            id="property-price"
                            placeholder="Enter price"
                            required=""
                          />
                          <ErrorMessage
                            name="price"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div> */}
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="vin">VIN</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.vin && touched.vin ? "is-invalid" : ""
                            }`}
                            name="vin"
                            id="property-vin"
                            placeholder="Enter VIN"
                          />
                          <ErrorMessage
                            name="vin"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="fuel_tank">Fuel Tank</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.fuel_tank && touched.fuel_tank
                                ? "is-invalid"
                                : ""
                            }`}
                            name="fuel_tank"
                            id="fuel-tank"
                            placeholder="Enter fuel tank capacity"
                            required=""
                          />
                          <ErrorMessage
                            name="fuel_tank"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="fuel_type">Fuel Type</label>
                          <Field
                            as="select"
                            className={`form-control ${
                              errors.fuel_type && touched.fuel_type
                                ? "is-invalid"
                                : ""
                            }`}
                            id="select-fuel_type"
                            name="fuel_type"
                          >
                            <option value="">Select Fuel Type</option>
                            <option value="petrol">Petrol</option>
                            <option value="diesel">Diesel</option>
                          </Field>
                          <ErrorMessage
                            name="fuel_type"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-7">
                        <div className="form-group">
                          <label htmlFor="days_hours">
                            Days and Hours of Operation
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.days_hours && touched.days_hours
                                ? "is-invalid"
                                : ""
                            }`}
                            name="days_hours"
                            id="days_hours"
                            placeholder="Enter days and hours of operation"
                          />
                          <ErrorMessage
                            name="days_hours"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="pickup_location">
                            Pick-up Location
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.pickup_location && touched.pickup_location
                                ? "is-invalid"
                                : ""
                            }`}
                            name="pickup_location"
                            id="pickup_location"
                            placeholder="Enter pick-up location"
                          />
                          <ErrorMessage
                            name="pickup_location"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="pickup_time">Pick-up Time</label>
                          <Field
                            type="time"
                            className={`form-control ${
                              errors.pickup_time && touched.pickup_time
                                ? "is-invalid"
                                : ""
                            }`}
                            name="pickup_time"
                            id="pickup_time"
                          />
                          <ErrorMessage
                            name="pickup_time"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="dropoff_location">
                            Drop-off Location
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.dropoff_location &&
                              touched.dropoff_location
                                ? "is-invalid"
                                : ""
                            }`}
                            name="dropoff_location"
                            id="dropoff_location"
                            placeholder="Enter drop-off location"
                          />
                          <ErrorMessage
                            name="dropoff_location"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="dropoff_time">Drop-off Time</label>
                          <Field
                            type="time"
                            className={`form-control ${
                              errors.dropoff_time && touched.dropoff_time
                                ? "is-invalid"
                                : ""
                            }`}
                            name="dropoff_time"
                            id="dropoff_time"
                          />
                          <ErrorMessage
                            name="dropoff_time"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="negotiable">Negotiable</label>
                          <Field
                            as="select"
                            className={`form-control ${
                              errors.negotiable && touched.negotiable
                                ? "is-invalid"
                                : ""
                            }`}
                            name="negotiable"
                            id="negotiable"
                          >
                            <option value="">Select Negotiable</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </Field>
                          <ErrorMessage
                            name="negotiable"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="link">Embeed Link</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.link && touched.link ? "is-invalid" : ""
                            }`}
                            name="link"
                            id="link"
                          />
                          <ErrorMessage
                            name="link"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                     
                      <div className="form-box ">
                        <div className="row ">
                          <div className="col-xs-12 col-sm-12 col-md-12 mt-20">
                            <h4 className="form--title">KEY FEATURES*</h4>
                          </div>
                          {feature?.map((amenity) => (
                            <div
                              key={amenity.name}
                              className="col-xs-12 col-sm-6 "
                            >
                              <div className="input-checkbox">
                                <label className="label-checkbox">
                                  <span>{amenity.name}</span>
                                  <input
                                    type="checkbox"
                                    id={amenity.name}
                                    onChange={handleCheckboxChange}
                                    checked={selectedAmenities.includes(
                                      amenity.name
                                    )}
                                  />
                                  <span className="check-indicator" />
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="form-box">
                        <label htmlFor="image">Car Image</label>
                        <input
                          type="file"
                          multiple
                          accept="image/*"
                          className="form-control"
                          onChange={onImageChange}
                        />
                        <div className="image-preview">
                          {imageURLs.map((imageSrc, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                display: "inline-block",
                                margin: "10px",
                              }}
                            >
                              <img
                                src={imageSrc}
                                alt="not found"
                                width={"250px"}
                              />
                              <button
                                type="button"
                                onClick={() => deleteImage(index, imageSrc)}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <i
                                  class="fa fa-trash-o"
                                  style={{
                                    color: "red",
                                    fontSize: "20px",
                                  }}
                                ></i>
                              </button>
                            </div>
                          ))}
                        </div>
                        {imageURLs.length > 0 && (
                          <button
                            type="button"
                            className="btn btn--primary"
                            onClick={deleteAllImages}
                            style={{ marginTop: "10px" }}
                          >
                            <i
                              class="fa fa-trash-o"
                              style={{
                                color: "red",
                                fontSize: "20px",
                              }}
                            ></i>{" "}
                            Delete All
                          </button>
                        )}
                      </div>
                      {/* Other form fields... */}
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="form-group">
                          <button type="submit" className="btn btn--primary">
                            {isSubmitting ? "Editing Car..." : "Edit Car"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EditCarForRent;
