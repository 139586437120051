/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "./Dialog/Dialog";
import { FaTiktok } from "react-icons/fa";

import { axiosData } from "../Config/Axios";
import { toast } from "react-toastify";

function Footer() {
  const navigate = useNavigate();

  const handleCarClick = (cityName, cheap) => {
    const Data = new FormData();
    const params = new URLSearchParams();

    Data.append("byPopularUsedCar", cityName);
    params.append("poularcar", cityName);
    // if (cheap === "cheap") {
    //   // Data.append("byDistrict", "Accra");
    //   Data.append("byMin", 100);
    //   Data.append("byMax", 600);
    // }
    axiosData
      .post("/car-search", Data)
      .then((response) => {
        console.log("Form submitted successfully!", response);

        if (response.data) {
          const queryString = params.toString();
          const url = queryString ? `/en-gh/car?${queryString}` : "/car";

          navigate(url, {
            state: response?.data?.cars,
          });

          toast.success(response?.data?.message);
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        navigate("/en-gh/car", {
          state: [],
        });

        // toast.error(error?.message);
      });
  };
  const handleCityClick = (cityName, cheap) => {
    const Data = new FormData();
    const params = new URLSearchParams();
    params.append("Popularcities", cityName);
    Data.append("byCity", cityName);

    axiosData
      .post("/car-search", Data)
      .then((response) => {
        console.log("Form submitted successfully!", response);

        if (response.data) {
          const queryString = params.toString();
          const url = queryString ? `/en-gh/car?${queryString}` : "/en-gh/car";

          navigate(url, {
            state: response?.data?.cars,
          });

          toast.success(response?.data?.message);
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        navigate("/en-gh/car", {
          state: [],
        });

        // toast.error(error?.message);
      });
  };
  const curentYear = new Date().getFullYear()

  return (
    <>
      <footer
        id="footer"
        className="footer footer-1 "
        style={{ position: "relative", top: "21px" }}
      >
        <div className="footer-widget">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-3 widget--about">
                <div className="widget--content">
                  <div className="footer--logo">
                    <img
                      src="/assets/images/logo-white.png"
                      alt="logo"
                      style={{ height: "35px", width: "189px" }}
                    />
                  </div>
                  <p className="nb">
                    CarsAreReady.com is an innovative online platform designed
                    to streamline the process of buying, selling, and renting
                    cars.
                  </p>
                  <div className="footer--contact">
                    <ul className="list-unstyled mb-0">
                      <li>
                        <i className="fa fa-map-marker" />:
                        <a
                          style={{ color: "#ffffff" }}
                          href="https://maps.app.goo.gl/dD7ykdc23BMLdM2j7"
                          target="_blank"
                        >
                          Near Togorme DA School
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-phone" />:
                        <a
                          style={{ color: "#ffffff" }}
                          href="tel:+233244453372"
                        >
                          +233 244 453 372
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-whatsapp" />:
                        <a
                          style={{ color: "#ffffff" }}
                          href="https://api.whatsapp.com/send?phone=233244453372"
                          target="_blank"
                        >
                          +233 244 453 372
                        </a>
                      </li>
                      <li>
                        <i className="fa fa-envelope" />:
                        <a
                          style={{ color: "#ffffff" }}
                          href="https://company.carsareready.com/s-email.php"
                        >
                          info@CarsAreReady.com
                        </a>
                      </li>
                    </ul>
                    <div className="row footer--bar">
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="social-icons">
                          <a href="https://company.carsareready.com/s-facebook.php">
                            <i className="fa fa-facebook" />
                          </a>
                          <a href="https://company.carsareready.com/s-instagram.php">
                            <i className="fa fa-instagram" />
                          </a>
                          <a href="https://company.carsareready.com/s-youtube.php">
                            <i className="fa fa-youtube" />
                          </a>
                          <a href="https://company.carsareready.com/s-tiktok.php">
                            <FaTiktok />
                          </a>
                          <a href="https://company.carsareready.com/s-twitter.php">
                            <i className="fa fa-twitter" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-sm-3 col-md-2 col-md-offset-1 widget--links">
                <div className="widget--title">
                  <h5>Useful Links</h5>
                </div>
                <div className="widget--content">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <Link to={"/en-gh"}>Home</Link>
                    </li>
                    <li>
                      <a
                        href="https://company.carsareready.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.carsareready.com/careers.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Careers
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.carsareready.com/guidelines.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Guidelines
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.carsareready.com/store.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Store
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.carsareready.com/blog.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Blog
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://company.carsareready.com/report.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Report an Issue
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.carsareready.com/faqs.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        FAQs
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.carsareready.com/terms.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Services
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.carsareready.com/privacy.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.carsareready.com/advertise.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Advertise with us
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://company.carsareready.com/contact.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xs-12 col-sm-3 col-md-2 widget--links">
                <div className="widget--title">
                  <h5>Popular Used Cars</h5>
                </div>
                <div className="widget--content">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <a href="#" onClick={() => handleCarClick("Toyota")}>
                        Toyota
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCarClick("Honda")}>
                        Honda
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCarClick("Hyundai")}>
                        Hyundai
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => handleCarClick("Mercedes Benz")}
                      >
                        Mercedes-Benz
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCarClick("Kia")}>
                        Kia
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCarClick("Chevrolet")}>
                        Chevrolet
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCarClick("Pontiac")}>
                        Pontiac
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCarClick("Mazda")}>
                        Mazda
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCarClick("Ford")}>
                        Ford
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCarClick("Jeep")}>
                        Jeep
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCarClick("Nissan")}>
                        Nissan
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCarClick("Volkswagen")}>
                        Volkswagen
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xs-12 col-sm-3 col-md-2 widget--links">
                <div className="widget--title">
                  <h5>Popular Cities</h5>
                </div>
                <div className="widget--content">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <a href="#" onClick={() => handleCityClick("Accra")}>
                        Accra
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Bolgatanga")}>
                        Bolgatanga
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Cape Coast")}>
                        Cape Coast
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Ho")}>
                        Ho
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Koforidua")}>
                        Koforidua
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Kumasi")}>
                        Kumasi
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Sunyani")}>
                        Sunyani
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Takoradi")}>
                        Takoradi
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Tamale")}>
                        Tamale
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Techiman")}>
                        Techiman
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Tema")}>
                        Tema
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleCityClick("Wa")}>
                        Wa
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer--copyright text-center ">
          <div className="container">
            <div className="row footer--bar">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <a
                  href="https://lispira.com"
                  className="text-white"
                  target="_blank"
                >
                  © {curentYear} Lispira Corporation (DBA CarsAreReady.com). All Rights
                  Reserved.
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <Dialog id={"signupModules"} />
    </>
  );
}

export default Footer;
