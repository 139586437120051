/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../Config/AxiosInstance";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { axiosData } from "../../Config/Axios";
import { PhoneFun, WhatsappFun } from "../../Helper/CallingServices";
import LoaderCard from "../Loader/LoaderCard";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

function FavoriteCom() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const GetData = () => {
    axiosInstance
      .get(`/car-favourite`)
      .then((res) => {
        if (res?.data?.status === true) {
          setData(res?.data?.data);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //   const handledelete = async (id) => {
  //     await axiosInstance
  //       .delete(`/listing/${id}`)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           toast.success("Deleted Succesfully");
  //           GetData();
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  useEffect(() => {
    GetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //   const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      {data?.length === 0 && (
        <div className="property-item col-mdd-6 flex-wrap">
          <LoaderCard lenght={2} />
        </div>
      )}
      <div className="newnew">
        {data?.map((e, i) => {
          return (
            <div className="property-item col-mdd-6">
              <div className="property--img">
                <a href="#">
                  <img
                    onClick={() => navigate(`/en-gh/singlecar/${e.id}`)}
                    src={e?.car_images?.[0]?.image}
                    alt="property image"
                    className="img-responsive"
                  />
                                             <span className={e?.carstype ==='sale' ? 'property--status':'property--statusred'}>

                    {" "}
                    {e?.carstype === "sale" ? "For Sale" : "For Rent"}
                  </span>
                </a>
              </div>
              <div className="property--content">
                <div className="property--info">
                  <h5 className="property--title">
                    <a href="#">{e?.model?.name}</a>
                  </h5>
                  <p className="property--location">
                    {" "}
                    <i className="fa fa-map-marker" />
                    {/* {e?.location} */}
                    {`${e?.city},${e?.district},${e?.region}`}
                  </p>
                  <div className="flex1">
                    {e?.carstype === "sale" ? (
                      <p
                        className="property--price "
                        style={{ display: "flex" }}
                      >
                        GH₵{e?.price}
                      </p>
                    ) : (
                      <p
                        className="property--price "
                        style={{ display: "flex" }}
                      >
                        GH₵{e?.price_per_day}
                        <sup>/Day</sup>
                      </p>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "100%",
                      }}
                    >
                      <button
                        className="bg-black p-xs border-none button-detail"
                        onClick={() => PhoneFun(e, user)}
                      >
                        <i className="fa fa-phone" style={{ color: "white" }} />
                      </button>
                      <button
                        className="btn--primaryw  p-xs ml-xs border-none button-detail"
                        onClick={() => WhatsappFun(e, user)}
                      >
                        <i
                          className="fa fa-whatsapp"
                          style={{ color: "white" }}
                        />
                      </button>
                      {e?.host?.is_verified == "1" && (
                        <img
                          style={{
                            width: "50px",
                            marginTop: "-3px",
                          }}
                          src="/assets/images/verify.webp"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="property--features">
                  {/* {/* <p>{e?.description.slice(0, 83)}</p> */}
                  <div className="flex1">
                    <div className="">
                      <img
                        className="mtt-10"
                        src="/assets/images/mileagelogo.svg"
                        alt=""
                      />
                      <span className="ml-nine bold font-20 text-black ">
                        {e?.mileage_type + " " + e?.mileage}
                      </span>
                    </div>
                    <div className="">
                      <img
                        className="mtt-10"
                        src="/assets/images/transmissionlogo.svg"
                        alt=""
                      />
                      <span className="ml-nine bold font-20 text-black ">
                        {e?.transmission}
                      </span>
                    </div>
                    <div className="">
                      <img
                        className="mtt-10"
                        src="/assets/images/enginelogo.svg"
                        alt=""
                      />
                      <span className="ml-nine bold font-20 text-black ">
                        {e?.engine_size?.name}
                      </span>
                    </div>
                    {/* <div className="">
                               
                                  <img
                                    className="mtt-10"
                                    src="https://static-assets.cargurus.com/images/site-cars/vdp-icons/icon-fuel-type_670bab1e0b56aac2d6635a5cb353099061396d70dca9c904ed4d5cf16c704c58.svg"
                                    alt=""
                                  />
                                  <span className="ml-nine bold font-20 text-black ">
                                    {e?.fuel_type}
                                  </span>
                                </div> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default FavoriteCom;
